import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL } from './URL';
import { Service } from './service';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';

@Injectable()
export class SalesValidationService extends Service {
  
  constructor(protected state: AppState, http: HttpClient) {
    super(http, {
      Authorization: 'Bearer ' + state.get('ACCESS_TOKEN')
    });
  }

  getCurrentSales(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current?offset`,{'filters':`offset=${offset}&&limit=${limit}${filter}`});
  }

  getErrors(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Error?offset`,{'filters':`offset=${offset}&&limit=${limit}${filter}`});
  }

  getCount(filter): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/Count?`,{'filters': filter});
  }

  getCurrentDownloadSales(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/Download?`,{'filters':`offset=${offset}&&limit=${limit}${filter}`});
  }

  getDownloadErrors(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Error/Download?`,{'filters':`offset=${offset}&&limit=${limit}${filter}&&deleteFlag=N`});
  }
  
 
  validate(filters,recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/validate?`,{'userId':`${this.state.get('userId')}`,'rec_Id':`${recIds.join(',')}`});
  }

  validate1(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/validate?`+recIds ,{'userId':`${this.state.get('userId')}`});
  }

  validateOverride(filters,recIds,activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/override?userId=${this.state.get('userId')}&orderBy=recId`,{'override': [parseInt(activityCode)],recordIds: recIds.join(',')});
  }
  
  validaterecord(filters,recIds,activityCode,typ): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/recode?userId=${this.state.get('userId')}&orderBy=recId&`+`&recodeFlag=${typ}`, {'override': [parseInt(activityCode)],recordIds: recIds.join(',')});
  }
  getvalidaterecord(filters,recIds,activityCode,typ): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/ErrorCode/Count?userId=${this.state.get('userId')}&orderBy=recId&`+ `recId=${recIds.join(',')}`+`&recodeFlag=${typ}`, {'override': [parseInt(activityCode)],recordIds: recIds.join(',')});
  }
  getvalidaterecord1(recIds,activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/ErrorCode/Count?userId=${this.state.get('userId')}&`+ recIds, {'override': [parseInt(activityCode)]});
  }

  validaterecord1(recIds,activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/recode?userId=${this.state.get('userId')}&`+recIds,{'override': [parseInt(activityCode)]});
  }
 
  validateOverride1(recIds,activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/override?userId=${this.state.get('userId')}&`+recIds,{'override': [parseInt(activityCode)]});
  }
  
  giveValidationStatus(execRequestId) {
    return this.get(URL.BASE_URL + `/direct/Sales/JobStatus?validationSetRunId=${execRequestId}`);
  }

  save(sales): Observable<any> {
    let userId =  this.state.get('userId');
    if(!userId) {
      userId = 'SYSTEM';
    }
    return this.put(URL.BASE_URL + `/direct/Sales/Current?userId=${userId}`, sales);
  }

  getTransReceiptDate(divCode): Observable<any> {
    return this.get(URL.BASE_URL +'/direct/Sales/' + `transReceiptDate?divCode=${divCode}`);
  }

  getTransSetControl(transDate, divCode): Observable<any> {
    return this.get(URL.BASE_URL+`/direct/Sales/salesSummary?transRecDate=${transDate}&divCode=${divCode}`)
  }

  getReportingLocation(transDate, divCode): Observable<any> {
    return this.get(URL.BASE_URL+`/direct/Sales/salesSummary/ReportingLocation?transRecDate=${transDate}&divCode=${divCode}`)
  }

  getWarehouse(transDate, divCode): Observable<any> {
    return this.get(URL.BASE_URL+`/direct/Sales/salesSummary/Warehouse?transRecDate=${transDate}&divCode=${divCode}`)
  }

  undeleteRecords(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/undelete?userId=${this.state.get('userId')}`,{recordIds: recIds.join(',')});
  }

  deleteRecords(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/delete?userId=${this.state.get('userId')}`,{recordIds: recIds.join(',')});
  }

  getTransActivity(id): Observable<any> {
    return this.get(URL.BASE_URL + `/direct/Sales/transActivity?validationSetIsn=${id}`);
  }

  getEventLog(id): Observable<any> {
    return this.get(URL.BASE_URL + `/direct/Sales/eventLog?id=${id}`);
  }

  overrideAllErrors(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Current/override?userId=${this.state.get('userId')}&orderBy=recId`,{'override': [404, 407, 408, 412, 413, 415, 416, 418, 425, 426, 429, 430, 431, 432, 434, 436, 999],recordIds: recIds.join(',')});
  }

  dataRefresh(): Observable<any> {
    return this.post(URL.BASE_URL + '/direct/Sales/dataprep', {});
  }

  getTransActivityAll(id, limit, offset): Observable<any> {
    return this.get(URL.BASE_URL + `/direct/Sales/transActivityAll?validationSetIsn=${id}&limit=${limit}&offset=${offset}`);
  }

  getValidationSets(): Observable<any> {
    return this.get(URL.BASE_URL + `/direct/Sales/validations`,{headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getvalidRecords(filters,recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Count/InProgress?`,{'userId':`${this.state.get('userId')}`,'rec_Id':`${recIds.join(',')}`});
  }

  getvalidRecords1(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/direct/Sales/Count/InProgress?`+recIds, {'userId':`${this.state.get('userId')}`});
  }
  checkStatus(trans_id){
    return this.get(URL.BASE_URL + `/ebizapistatus/getResponse?transId=${trans_id}`);
  }
  public getJSON(filename): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/txt',
        responseType: 'blob',
        Accept: 'application/txt',
        observe: 'response'
      })
    };
    return this.http.get(URL.ENV_URL+`${filename}`, httpOptions);
  }
}

// Accept: 'application/json',
      // 'Content-Type': 'application/json',
      // Authorization: 'Bearer ' + state.get('ACCESS_TOKEN')


