import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL } from './URL';
import { Service } from './service';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';

@Injectable()
export class InventoryCurrentService extends Service {

  constructor(protected state: AppState, http: HttpClient) {
    super(http, {
      Authorization: 'Bearer ' + state.get('ACCESS_TOKEN')
    });
  }

  getCurrentSales(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current?`,{'filters':`offset=${offset}&&limit=${limit}${filter}`} ,{headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getErrors(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Error?`,{'filters':`offset=${offset}&&limit=${limit}&&${filter}&&deleteFlag=N`}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  deleteRecords(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current/delete?userId=${this.state.get('userId')}`,{recordIds: recIds.join(',')}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  undeleteRecords(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current/undelete?userId=${this.state.get('userId')}`,{recordIds: recIds.join(',')}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getCount(filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/Count?`,{'filters':`${filter}`}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }
  validate(filters,recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/validate?`+`${filters.join('&')}&inProgressFlag=N`, {'recordId':`${recIds.join(',')}`,'userId':`${this.state.get('userId')}`});
  }

  validate1(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/validate?`+recIds + `&inProgressFlag=N`,{'userId':`${this.state.get('userId')}`});
  }

  giveValidationStatus(execRequestId) {
    return this.get(URL.BASE_URL + `/inventory/JobStatus?validationSetRunId=${execRequestId}`);
  }

  save(sales): Observable<any> {
    let userId =  this.state.get('userId');
    if(!userId) {
      userId = 'SYSTEM';
    }
    return this.put(URL.BASE_URL + `/inventory/Current?userId=${userId}`, sales);
  }


  getTransReceiptDate(divCode): Observable<any> {
    return this.get(URL.BASE_URL+'/inventory/' + `transReceiptDate?divisionCode=${divCode}`, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getTransSetControl(transDate, divisionCode): Observable<any> {
    return this.get(URL.BASE_URL+`/inventory/inventorySummary?transactionReceiptDate=${transDate}&divisionCode=${divisionCode}`, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getReportingLocation(transDate, divisionCode): Observable<any> {
    return this.get(URL.BASE_URL+`/inventory/inventorySummary/ReportingLocation?transactionReceiptDate=${transDate}&divisionCode=${divisionCode}`, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) })
  }

  getWarehouse(transDate, divisionCode): Observable<any> {
    return this.get(URL.BASE_URL+`/inventory/inventorySummary/Warehouse?transRecDate=${transDate}&divisionCode=${divisionCode}`, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) })
  }

  overrideAllErrors(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/override?userId=${this.state.get('userId')}`, {'recordIds': recIds.join(','),'override': [404, 407, 408, 426, 434, 435, 999]}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  overrideError(recIds, activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/override?userId=${this.state.get('userId')}`, {'recordIds': recIds.join(','),'override': [activityCode]}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  overrideErrorforbatch(recIds,filtr,activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current/override1?userId=${this.state.get('userId')}&${recIds}&${filtr}&activityCodeInventory=${activityCode}&overridenFlag=N`, {'recordIds': '','override': [activityCode]}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getRefresh(source): Observable<any> {
    return this.get(URL.BASE_URL+`/inventory/Current/lastrefresh?source=${source}&userId=${this.state.get('userId')}`, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) })
  }

  triggerRefresh(source): Observable<any> {
    return this.get(URL.BASE_URL+`/inventory/Current/trigger?userId=${this.state.get('userId')}&source=${source}`, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) })
  }

  overrideErrorForAllRecords(filter, activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/override?userId=${this.state.get('userId')}&${filter}`, {'recordIds': '','override': [activityCode]}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getvalidRecords(filters,recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Count/InProgress?`+`${filters.join('&')}`, {'recordId':`${recIds.join(',')}`,'userId':`${this.state.get('userId')}`}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getvalidRecords1(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Count/InProgress?`+recIds, {'userId':`${this.state.get('userId')}`}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

  getCurrentSalesDownloads(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/Download?`,{'filters':`offset=${offset}&&limit=${limit}${filter}`} ,{headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }
  getErrorsDownloads(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Error/Download?`,{'filters':`offset=${offset}&&limit=${limit}&&${filter}&&deleteFlag=N`}, {headers:  new HttpHeaders({
      Authorization: 'Bearer ' + this.state.get('ACCESS_TOKEN')
    }) });
  }

}
